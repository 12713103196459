<template>
<div class="share">
  <ShareNetwork
    v-for="network in networks"
    :network="network.network"
    :key="network.network"
    :url="sharing.url"
    :title="sharing.title"
    :description="sharing.description"
  >
    <img :src="network.icon" :alt="network.name ">
    <span>{{ network.name }}</span>
  </ShareNetwork>
</div>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    ShareNetwork
  },
  props: {
    url: String,
  },
  data () {
    return {
      sharing: {
        url: 'https://' + this.url,
        title: 'Yu gabung di doo-id banyak hadiah menarik tiap harinya.',
        description: 'doo-id adalah game yang menarik dan selalu ada hadiah tiap harinya.'
      },
      networks: [
        { network: 'whatsapp', name: 'Whatsapp', icon: 'img/social/whatsapp.svg' },
        { network: 'facebook', name: 'Facebook', icon: 'img/social/facebook.svg' },
        { network: 'telegram', name: 'Telegram', icon: 'img/social/telegram.svg' },
        { network: 'twitter', name: 'Twitter', icon: 'img/social/twitter.svg' },
        { network: 'email', name: 'Email', icon: 'img/social/email.svg' },
      ]
    }
  }
})
</script>

<style lang="scss">
.share {
  display: flex;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    width: 80px;
    min-width: 80px;
    img {
      width: 40px;
    }
    span {
      text-align: center;
      padding: 10px;
      color: #77787B;
      font-size: 12px;
    }
  }
}
</style>
