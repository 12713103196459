<template>
  <ion-page>
    <Header title="Referal" />
    <ion-content :fullscreen="true" v-if="$auth">
      <div class="container">
        <div class="center flex col-center mb-2">
          <div class="icon">
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M45.2787 94.6433C64.4741 94.6433 80.035 93.0989 80.035 91.1937C80.035 89.2886 64.4741 87.7442 45.2787 87.7442C26.0834 87.7442 10.5225 89.2886 10.5225 91.1937C10.5225 93.0989 26.0834 94.6433 45.2787 94.6433Z" fill="url(#paint0_radial)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M57.8355 5.46224C53.5048 6.62266 50.765 10.7859 51.2544 15.1127L49.3291 18.4474C45.9781 24.2515 39.969 28.7907 33.2545 30.5899L15.9553 35.2252C12.1966 36.2323 9.63774 39.5016 9.34676 43.1763L8.85085 43.3092C2.49168 45.0131 -1.29565 51.573 0.408278 57.9322C2.11221 64.2913 8.67207 68.0787 15.0312 66.3747L15.5271 66.2419C17.0547 68.4621 19.5233 69.8873 22.2098 70.1001L26.2055 85.012C27.4834 89.7813 32.4035 92.6218 37.1729 91.3439C41.9421 90.066 44.7826 85.1461 43.5046 80.3767L39.6419 65.9607L42.5251 65.1882C49.2394 63.3891 56.713 64.3156 62.5173 67.6666L65.8521 69.5919C67.5916 73.5835 72.0461 75.8192 76.3769 74.6588C81.1461 73.3809 83.9866 68.461 82.7086 63.6916L68.8027 11.7942C67.5248 7.02479 62.6049 4.18429 57.8355 5.46224Z" fill="#FF480B"/>
              <path d="M95.9322 32.3347L87.2826 34.6524C85.6903 35.079 84.7453 36.7158 85.172 38.3081C85.5986 39.9004 87.2354 40.8454 88.8277 40.4188L97.4773 38.1011C99.0696 37.6745 100.015 36.0377 99.5879 34.4454C99.1613 32.8531 97.5245 31.9081 95.9322 32.3347Z" fill="#FF480B"/>
              <path d="M97.0667 51.4763L89.7552 47.255C88.3278 46.4308 86.5021 46.92 85.6778 48.3475C84.8533 49.7751 85.3425 51.6007 86.7703 52.4249L94.0818 56.6462C95.5093 57.4705 97.3352 56.9813 98.1592 55.5537C98.9835 54.126 98.4944 52.3005 97.0667 51.4763Z" fill="#FF480B"/>
              <path d="M87.8066 16.877C86.3792 16.0529 84.5535 16.5421 83.7292 17.9696L79.5079 25.2811C78.6836 26.7087 79.1728 28.5342 80.6005 29.3585C82.0281 30.1827 83.8537 29.6934 84.6779 28.2659L88.8991 20.9544C89.7234 19.5268 89.2343 17.7013 87.8066 16.877Z" fill="#FF480B"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4914 80.3819C43.9174 81.9717 42.9706 83.6117 41.3808 84.0376C39.791 84.4636 38.151 83.5168 37.725 81.927L33.8623 67.511L39.6287 65.966L43.4914 80.3819Z" fill="#C52100"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M69.0704 26.6547C69.3204 27.5876 68.7648 28.5499 67.832 28.7999L67.8319 28.7999C66.899 29.0499 65.9367 28.4943 65.6867 27.5614L61.635 12.4401C61.385 11.5073 61.9406 10.5449 62.8736 10.2949C63.8064 10.045 64.7687 10.6006 65.0187 11.5335L69.0704 26.6547Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M71.6289 36.225C71.8789 37.1579 71.3232 38.1202 70.3905 38.3701L70.3904 38.3702C69.4575 38.6201 68.4951 38.0645 68.2452 37.1316L67.2746 33.5095C67.0247 32.5766 67.5803 31.6143 68.5133 31.3643C69.446 31.1143 70.4084 31.6699 70.6583 32.6028L71.6289 36.225Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M63.5717 61.4684C57.5713 58.5579 47.6881 61.1991 43.364 56.8632C39.0399 52.5272 36.5639 43.2862 38.1408 37.3691C39.7176 31.452 49.5972 28.798 53.3384 23.2771L63.5717 61.4684Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5287 66.2233C8.8909 68.0019 2.10532 64.2788 0.402546 57.924C-1.30023 51.5691 2.71472 44.9521 9.35249 43.1735L15.5287 66.2233Z" fill="#C52100"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.34818 43.1097C9.66512 39.463 12.2154 36.2243 15.9488 35.2239L33.2511 30.5878C39.9627 28.7894 45.9727 24.2498 49.323 18.4469L51.2517 15.1119C51.2463 15.0763 51.2399 15.037 51.2383 15.0004L51.3823 15.0317L54.027 24.9017C54.027 24.9017 51.9106 35.2177 32.538 40.4989C20.8683 43.6793 15.002 47.6668 11.9035 52.6463L9.34818 43.1097Z" fill="#C52100" fill-opacity="0.25"/>
              </g>
              <defs>
              <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45.2787 91.1938) scale(34.7562 3.44951)">
              <stop stop-opacity="0.25"/>
              <stop offset="1" stop-opacity="0"/>
              </radialGradient>
              <clipPath id="clip0">
              <rect width="100" height="100" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
          <h3 class="s18 bold">
            Ajak Teman
          </h3>
          <div class="text-sm text-center">
            Sebanyak-banyaknya dan kumpulkan semua poinnya!
          </div>
        </div>
        <div class="input-referral input-group">
          <ion-input type="text" :value="$auth.ref_link" ref="referral" readonly></ion-input>
          <div class="after btn">
            <div class="btn btn-input bg-black" @click="copy">Salin</div>
          </div>
        </div>
      </div>
      <div class="scroll">
        <Share :url="$auth.ref_link" />
      </div>

      <h5 class="page-title">Status Referal</h5>
      <ion-list>
        <ion-item v-for="item in items" :key="item.id">
          <ion-label>
            {{ item.name }}
            <div class="sub-label">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99992 9.16666C7.30111 9.16666 9.16659 7.30118 9.16659 5C9.16659 2.69881 7.30111 0.833328 4.99992 0.833328C2.69873 0.833328 0.833252 2.69881 0.833252 5C0.833252 7.30118 2.69873 9.16666 4.99992 9.16666Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 2.5V5L6.66667 5.83333" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $filters.dateFormat(item.created_at, 'full') }}
            </div>
          </ion-label>
          <ion-note slot="end" class="amount debit"></ion-note>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="getReferrals"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, toastController, IonList, IonItem, IonLabel, IonNote, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'
import Share from '@/components/share'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    Header,
    Share,
    IonList, IonItem, IonLabel, IonNote,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  data () {
    return {
      referral_code: null,
      items: [],
      page: 1,
      isDisabled: false
    }
  },
  created () {
    this.getReferrals()
  },
  methods: {
    copy () {
      navigator.clipboard.writeText(this.$auth.ref_link);
      toastController.create({
          message: 'code referal di copy.',
          duration: 2000,
          color: 'success',
        }).then(r => r.present())
    },
    async getReferrals (e) {
      let data = await this.$api.get('/api/referrals', {params: {page: this.page}}).then(res => res)
      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }
      data.data.forEach(c => {
        this.items.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();
      this.page++
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss">
.input-group {
  .after.btn {
    padding: 0 !important;
  }
  .btn-input {
    border-radius: 0 5px 5px 0;
    padding: 14px;
    cursor: pointer;
  }
}
.scroll {
  overflow: auto;
  white-space: nowrap;
}

.sub-label {
  font-size: 12px;
  color: #545454;
  margin-top: 5px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}
.amount {
  color: #EB5757;
  font-size: 16px;
  &.debit {
    color: #219653;
  }
}
h5.page-title {
  margin: 0 1rem;
  margin-top: 2rem;
}
</style>
